import React, { useEffect, useState } from 'react'
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerBanner from '../../Components/InnerBanner';
import axios from 'axios';
import { Link } from 'react-router-dom';
const Blogs = () => {
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const getBlogs = async () => {
        setLoading(true)
        setError(false)
        try {
            const data = [
                {
                    "module": "get_blogs"
                }
            ];
            const res = await axios.post('https://www.nuchain.co/webapi/?appkey=dbRsqhjwkQGwKXYT3urPgufmYG6NspLgvG0xrL6a', data)
            setBlogs(res?.data)
            setLoading(false)
            console.log(res)
        }
        catch (error) {
            console.log('Something went wrong', error)
            setLoading(false)
            setError(true)
        }
    }
    useEffect(() => {
        getBlogs()
    }, [])
    function calculateDaysAgo(created_on) {
        const createdDate = new Date(created_on);
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - createdDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }
    if (error) {
        return (
            <>
                <Header />
                <main>
                    <InnerBanner
                        sectionname="Connect with Nuchain"
                        heading="Unveiling Insights"
                        subheading="Reach out to us in the way its works best for you."
                        imageurl="assets/bannerimages/blog-grid-min.png"
                    />
                    <div className='container-xxl'>
                        <h1>Something went wrong</h1>
                    </div>
                </main>
                <Footer />
            </>
        )
    }
    return (
        <>
            <Header />
            <main>
                <InnerBanner
                    sectionname="Connect with Nuchain"
                    heading="Unveiling Insights"
                    subheading="Reach out to us in the way its works best for you."
                    imageurl="assets/bannerimages/blog-grid-min.png"
                />
                {loading ?
                    <div class="text-center mt-4">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div> : <>
                        <section className='blog-hero-thumbnils blog-start-section '>
                            <div className='container-xxl'>
                                <div className='row'>
                                    <div className='col col-12 col-md-8'>

                                        {blogs.map((blog, index) => {
                                            if (index === 0) {
                                                return (
                                                    <div className=' position-relative blog-thumbnil-image-box-main mb-4 mb-md-0 d-flex align-items-end'>
                                                        <img className='position-absolute z-n1' src={blog?.image} alt='blog-image' />
                                                        <Link className='position-absolute z-1 w-100' to={`/blog-details`} state={blog} key={blog.id}>
                                                            <div className='content-box d-flex justify-content-end flex-column'>
                                                                <h3>{blog.title}</h3>
                                                                <p>Published {calculateDaysAgo(blog.created_on)} Days Ago</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}

                                    </div>

                                    <div className='col col-12 col-md-4 d-flex flex-column justify-content-end'>
                                        <div className='row gap-4'>
                                            <div className='col col-12'>
                                                {blogs.map((blog, index) => {
                                                    if (index === 1) {
                                                        return (
                                                            <div className='position-relative blog-thumbnil-image-box d-flex align-items-end'>
                                                                <img className='position-absolute z-n1' src={blog?.image} alt='blog-image' />
                                                                <Link className='position-absolute z-1 w-100' to={`/blog-details`} state={blog} key={blog.id}>
                                                                    <div className='content-box-small d-flex justify-content-end flex-column'>
                                                                        <h3>{blog.title}</h3>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                            <div className='col col-12'>
                                                {blogs.map((blog, index) => {
                                                    if (index === 2) {
                                                        return (
                                                            <div className='position-relative blog-thumbnil-image-box d-flex align-items-end'>
                                                                <img className='position-absolute z-n1' src={blog?.image} alt='blog-image' />
                                                                <Link className='position-absolute z-1 w-100' to={`/blog-details`} state={blog} key={blog.id}>
                                                                    <div className='content-box-small d-flex justify-content-end flex-column'>
                                                                        <h3>{blog.title}</h3>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='blog-start-section'>
                            <div className='container-xxl'>
                                <div className='row'>
                                    {blogs.length === 3 ? "" :
                                        <>
                                            <h2 className='blog-setion-heading'>From the top picks</h2>
                                            {blogs.slice(3).map((blog) => (
                                                <div className='col col-12 col-md-4 mb-4 pb-3'>
                                                    <Link
                                                        to={`/blog-details`}
                                                        state={blog}>
                                                        <div className='blog-thumbnil-image-box'>
                                                            <img src={blog?.image} alt='blog-image' />
                                                        </div>
                                                        <h3 className='blog-title'>{blog?.title}</h3>
                                                        <p className='blog-date mb-3 mb-md-0'><span>Published</span> {calculateDaysAgo(blog?.created_on)} Days Ago</p>
                                                    </Link>
                                                </div>
                                            ))}
                                        </>
                                    }

                                </div>
                            </div>
                        </section>
                    </>}
                {/* <section className='blog-start-section'>
                            <div className='container-xxl'>
                                <div className='row'>
                                    <h2 className='blog-setion-heading'>Blogs your have read before</h2>
                                    <div className='col col-12 col-md-4'>
                                        <div className='blog-thumbnil-image-box'>
                                            <img src='assets/images/blog-image-demo.png' alt='blog-image' />
                                        </div>
                                        <h3 className='blog-title'>Web3 Labs Joins Hyperledger Foundation, Reinforcing Commitment to Hyperledger Besu Development</h3>
                                        <p className='blog-date mb-3 mb-md-0'><span>Published</span> 5 Days Ago</p>
                                    </div>
                                    <div className='col col-12 col-md-4'>
                                        <div className='blog-thumbnil-image-box'>
                                            <img src='assets/images/blog-image-demo-1.png' alt='blog-image' />
                                        </div>
                                        <h3 className='blog-title'>What AI Means for the Future of Content Creation — an Entrepreneur's Perspective</h3>
                                        <p className='blog-date mb-3 mb-md-0'><span>Published</span> 5 Days Ago</p>
                                    </div>
                                    <div className='col col-12 col-md-4'>
                                        <div className='blog-thumbnil-image-box'>
                                            <img src='assets/images/blog-image-demo-2.png' alt='blog-image' />
                                        </div>
                                        <h3 className='blog-title'>Revolutionizing Creativity: The Untapped Potential of Text-Based NFTs</h3>
                                        <p className='blog-date mb-3 mb-md-0'><span>Published</span> 5 Days Ago</p>
                                    </div>
                                </div>
                            </div>
                        </section> */}
            </main>
            <Footer />
        </>
    )
}

export default Blogs
