import React, { useEffect } from 'react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import ApplyJobForm from '../../Components/forms/ApplyJobForm'
import InnerBanner from '../../Components/InnerBanner'

const JobApplicationForm = () => {
   
    return (
        <>
            <Header />
            <main>
                <InnerBanner
                    sectionname="Build Your Future"
                    heading="Careers"
                    subheading="Join the Blockchain Revolution"
                    imageurl="assets/bannerimages/apply-jobs-min.png"
                />
                <section className='the-company-section apply-job-form'>
                    <div className='container-xxl'>
                        <ApplyJobForm />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default JobApplicationForm
