import { useRoutes } from "react-router-dom"

// import WhyNuchain from "./../Pages/AboutUS/WhyNuchain";
import TheTeam from './../Pages/AboutUS/TheTeam';
import Licence from './../Pages/AboutUS/Licence';
import Careers from './../Pages/AboutUS/Careers';
import ContactUs from './../Pages/AboutUS/ContactUs';
import ApplyJobPage from "./../Pages/AboutUS/ApplyJobPage";
import EGovernance from './../Pages/Services/EGovernance';
import BankingAndFinance from './../Pages/Services/BankingAndFinance';
import Travel from "./../Pages/Services/Travel";
import RWA from './../Pages/Services/RWA';
import GammingAndEntertainment from './../Pages/Services/GammingAndEntertainment';
import Tokenization from "./../Pages/Services/Tokenization";
import NuCode from "./../Pages/Product/NuCode";
import Partners from './../Pages/Ecosystem/Partners';
import Community from './../Pages/Ecosystem/Community';
import Media from './../Pages/Ecosystem/Media';
import Ambasadors from './../Pages/Ecosystem/Ambasadors';
import Blogs from './../Pages/Resources/Blogs';
import Support from "./../Pages/Resources/Support";
import BlogDetails from './../Pages/Resources/BlogDetails';
import TermsofService from './../Pages/Legal/TermsofService';
import PrivacyPolicy from './../Pages/Legal/PrivacyPolicy';
import CookiesPolicy from './../Pages/Legal/CookiesPolicy';
import DataProcessing from './../Pages/Legal/DataProcessing';
import JobApplicationForm from "./../Pages/AboutUS/JobApplicationForm";
import HomePage from './../Pages/HomePage';
import Whynuchainnew from "../Pages/AboutUS/Whynuchainnew";
import Validator from "../Pages/Validator";
import ToBelive from "../Pages/Tobelive";

const Route = () => {
    let element = useRoutes([
        { path: "/", element: <HomePage /> },
        { path: "*", element: <HomePage /> },
        // { path: "/validator", element: <Validator /> },
        { path: "/tobelive", element: <ToBelive /> },

        // ============== about us ==============

        { path: "/why-nuchain", element: <Whynuchainnew /> },
        { path: "/the-team", element: <TheTeam /> },
        { path: "/licences", element: <Licence /> },
        { path: "/careers", element: <Careers /> },
        { path: "/contact-us", element: <ContactUs /> },

        { path: "/apply-jobs", element: <ApplyJobPage /> },  // Dynamic Route
        { path: "/job-application", element: <JobApplicationForm /> },  // Dynamic Route

        // ============== Services ==============

        { path: "/e-governance", element: <EGovernance /> },
        { path: "/banking-and-finance", element: <BankingAndFinance /> },
        { path: "/travel", element: <Travel /> },
        { path: "/rwa", element: <RWA /> },
        { path: "/gaming-and-entertainment", element: <GammingAndEntertainment /> },
        { path: "/tokenization", element: <Tokenization /> },


        // ============== Product ==============
        { path: "/nucode", element: <NuCode /> },

        // ============== Ecosystem ==============
        { path: "/partners", element: <Partners /> },
        { path: "/community", element: <Community /> },
        { path: "/media-kit", element: <Media /> },
        { path: "/ambassadors", element: <Ambasadors /> },

        // ============== Resources ==============
        { path: "/blogs", element: <Blogs /> },
        { path: "/support", element: <Support /> },
        // { path: "/blog-details/:slug", element: <BlogDetails /> },   // Dynamic Route
        { path: "/blog-details", element: <BlogDetails /> },   // Dynamic Route



        // { path: "/documentation", element: <Documentation /> },
        // { path: "/explorer", element: <Explorer /> },
        // { path: "/network-status", element: <NetworkStatus /> },
        // { path: "/podcasts", element: <Podcasts /> },
        // { path: "/press", element: <Press /> },
        // { path: "/datasheet", element: <Datasheet /> },

        { path: "/terms-of-service", element: <TermsofService /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/cookies-policy", element: <CookiesPolicy /> },
        { path: "/data-processing", element: <DataProcessing /> }
    ])

    return element
}

export default Route