import React from 'react'
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerBanner from '../../Components/InnerBanner';
import CalltoAction from '../../Components/CalltoAction';
import ParaImage from '../../Components/ParaImage';

const Community = () => {

    const firstSectionContent = (
        <>
            <h3>Where Innovation <br /><span>Meets Collaboration</span>.</h3>
            <p>NuChain's community isn't just builders - it's a global network of passionate developers, enthusiasts, and thought leaders. Together, they explore Blockchain's potential and shape the future. Join the conversation and be part of the movement!</p>
            <a href="https://x.com/NuChainOfficial" className="btn-pink mt-4">Join our Community</a>
        </>
    );

    return (
        <>
            <Header />
            <main>
                <InnerBanner
                    sectionname="Connect with Nuchain"
                    heading="Community"
                    subheading="Reach out to us in the way its works best for you."
                    imageurl="assets/bannerimages/community-min.png"
                />

                <ParaImage
                    image="assets/sectionimages/community-1.png"
                    content={firstSectionContent}
                    position="right"
                    background="white"
                />

                <CalltoAction cta="Connect, learn, and grow. Join the NuChain Community." />
            </main>
            <Footer />
        </>
    )
}

export default Community
