import React from 'react'
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import InnerBanner from '../../Components/InnerBanner';

const TheTeam = () => {

    const cxo = [
        { name: "Jagdish Pandya", position: "Founder & CEO", photo: "assets/team/8.png", linkedin: "https://www.linkedin.com/in/jagdishpandya/" },
        { name: "Captain Ajay Ahlawat", position: "Chief Operations Officer", photo: "assets/team/10.png", linkedin: "https://www.linkedin.com/in/group-captain-ajay-ahlawat-veteran-954870145" },
        { name: "Dhanraj Dadhich", position: "Chief Techinical Officer", photo: "assets/team/5.png", linkedin: "https://www.linkedin.com/in/DhanrajDadhich/" }
    ];
    const teamdata = [
        { name: "Praveer Tiwari", position: "Business Analyst", photo: "assets/team/9.png", linkedin: "https://www.linkedin.com/in/praveer-tiwari/" },
        { name: "Sanskriti Negi", position: "Senior Business Development Executive", photo: "assets/team/7.png", linkedin: "https://www.linkedin.com/in/sanskriti-negi-2843251a6/" },
        { name: "Khushi Panwar", position: "Community and Growth Manager", photo: "assets/team/6.png", linkedin: "https://www.linkedin.com/in/smilewithkhushi/" }
    ];

    return (
        <>
            <Header />
            <main>
                <InnerBanner
                    sectionname="Our Team"
                    heading="Unity in diversity"
                    subheading="The secret sauce behind our success."
                    imageurl="assets/bannerimages/teams-min.png"
                />
                <section className='section-spacing-design'>
                    <div className='container-xxl'>
                        <div className='row justify-content-center'>
                            {cxo.map((member, index) => (
                                <div key={index} className='col col-6 col-md-4 team-border-design'>
                                    <div className='pt-md-5 pb-mg-5 pt-4 pb-4 d-flex justify-content-center align-items-center flex-column'>
                                        <div className='member-image-design'>
                                            <img src={member.photo} alt={member.name} />
                                            <div className='linkedin-box-design'>
                                                <a href={member.linkedin}><i className="bi bi-linkedin" /></a>
                                            </div>
                                        </div>
                                        <p className='member-name'>{member.name}</p>
                                        <p className='member-position'>{member.position}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='row justify-content-center'>
                            {teamdata.map((member, index) => (
                                <div key={index} className='col col-6 col-md-4 team-border-design'>
                                    <div className='pt-md-5 pb-mg-5 pt-4 pb-4 d-flex justify-content-center align-items-center flex-column'>
                                        <div className='member-image-design'>
                                            <img src={member.photo} alt={member.name} />
                                            <div className='linkedin-box-design'>
                                                <a href={member.linkedin}><i className="bi bi-linkedin" /></a>
                                            </div>
                                        </div>
                                        <p className='member-name'>{member.name}</p>
                                        <p className='member-position'>{member.position}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    )
}

export default TheTeam
