import * as yup from "yup";

const BecomeAnValidatorValidation = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    contact_number: yup.string().required("Contact number is required"),
    become_a_franchisee: yup.string().required("Sector is required"),
  
});

export default BecomeAnValidatorValidation;