import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

SingleBlogBanner.propTypes = {
    sectionname: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    imageurl: PropTypes.string,
};

export default function SingleBlogBanner({ imageurl, text, sectionname, heading, subheading, ...other }) {
    const { state } = useLocation();
    function calculateDaysAgo(created_on) {
        const createdDate = new Date(created_on);
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - createdDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }
    console.log('Inside Banner',state);
    return (

        <section className="single-section-banner" style={{ backgroundImage:"url(assets/images/blog-banner.png)", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className="container-xxl d-flex justify-content-center align-items-center">
                <div className="col col-12 col-md-10 text-center">
                    <h1 className="white" style={{fontSize: "calc(2rem + 0.9vh)"}}>{state?.title}</h1>
                    <p className="white mb-lg-5 mb-3">Published {calculateDaysAgo(state?.created_on)} Days Ago</p>
                </div>
            </div>
        </section>
    )
}