import React from 'react'
import Header from '../Layout/Header'
import InnerBanner from '../Components/InnerBanner'
import ValidatorForm from '../Components/forms/ValidatorForm'
import Footer from '../Layout/Footer'

const ToBelive = () => {
    return (
        <>
            <Header />
            <main>
                <InnerBanner
                    sectionname="Blockchain Reimagined"
                    heading="NuChain"
                    subheading="Empowering Enterprises"
                    imageurl="assets/bannerimages/the-company-min.png"
                />

                <section>
                    <div className="container-xxl">
                        <div className="row mt-5">
                            <div className='col-md-3 p-3'>
                                <div className='border product-box h-100 d-flex flex-column gap-3 justify-content-between align-items-center p-4'>
                                    <img src='assets/products/nucode.png' alt='Icon' />
                                    <a className='center-button1111 button-style1111' target='_blank' href='https://www.nuchain.co/product-design/NuCode.pdf'>Explore</a>
                                </div>
                            </div>
                            <div className='col-md-3 p-3'>
                                <div className='border product-box h-100 d-flex flex-column gap-3 justify-content-between align-items-center p-4'>
                                    <img src='assets/products/nuscan.png' alt='Icon' />
                                    <a className='center-button1111 button-style1111' target='_blank' href='https://www.nuchain.co/product-design/Explorer.pdf'>Explore</a>
                                </div>
                            </div>
                            <div className='col-md-3 p-3'>
                                <div className='border product-box h-100 d-flex flex-column gap-3 justify-content-between align-items-center p-4'>
                                    <img src='assets/products/nustaking.png' alt='Icon' />
                                    <a className='center-button1111 button-style1111' target='_blank' href='https://www.nuchain.co/product-design/Validator.pdf'>Explore</a>
                                </div>
                            </div>
                            <div className='col-md-3 p-3'>
                                <div className='border product-box h-100 d-flex flex-column gap-3 justify-content-between align-items-center p-4'>
                                    <img src='assets/products/nuwallet.png' alt='Icon' />
                                    <a className='center-button1111 button-style1111' target='_blank' href='https://www.nuchain.co/product-design/Wallet.pdf'>Explore</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default ToBelive





