import React from 'react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'

const PrivacyPolicy = () => {

    return (
        <>
            <Header />
            <main>
                <section className='section-spacing-design content-design'>
                    <div className='container-xxl'>

                        <h6>Legal</h6>
                        <h1>Privacy Policy</h1>

                        <p>
                            This Privacy Policy (the <strong>“Policy”</strong>) applies to
                            <strong>[Name of Nevis Company]</strong> (hereinafter collectively
                            referred to as
                            <strong>“NuChain”, “Company”, “Platform”, “We”, “Us” or “Our”</strong>).
                            This Policy outlines NuChain's practices regarding the collection, use,
                            and disclosure of user information. This includes but is not limited to
                            the NuChain website <a href="">https://nuchain.co/ </a>, all its
                            subdomains, and associated applications ( the<strong> “App”</strong>,
                            together with the website, the <strong>“Website”</strong>)
                        </p>
                        <p>
                            NuChain is dedicated to providing secure, scalable and customizable
                            blockchain infrastructure that enable enterprises and governments to
                            streamline operations, enhance efficiency, and safeguard sensitive data.
                            Our mission is to simplify the adoption of blockchain technology, helping
                            enterprises and governments to innovate and unlock new opportunities in a
                            compliant and sustainable way.
                        </p>
                        <p>
                            Nuchain, being the first blockchain platform as a service (BaaS) globally,
                            is a benchmark for easily navigable, expandable, and safe blockchain
                            solutions that cater to the many requirements of modern business.
                        </p>
                        <p>
                            NuChain is committed to protecting the privacy of our clients, customers,
                            and website visitors. As such, we have implemented policies and procedures
                            to ensure that we comply with the European Union - General Data Protection
                            Regulation ( EU- GDPR), Data Protection Act 5 of 2018 of Saint Kitts and
                            Nevis and other applicable data protection laws and regulations.
                        </p>
                        <p>
                            This Privacy Policy applies to all personal information that we collect,
                            use, and process in the course of our business activities, including
                            information collected through our website. It explains how we process
                            personal data, what we do with it, and how we keep it secure.
                        </p>
                        <p>
                            We understand that your personal data is important to you, and we take our
                            responsibility to protect it seriously. We are committed to being
                            transparent about our data processing practices, and we strive to provide
                            clear and concise information to help you understand how we use your
                            personal data.
                        </p>
                        <p>
                            In addition to complying with the GDPR, we also adhere to best practices
                            in data protection and privacy. We have implemented a range of technical
                            and organizational measures to ensure the security and confidentiality of
                            personal data. Our employees are trained on data protection and privacy
                            best practices, and we conduct regular audits to ensure that our policies
                            and procedures remain up-to-date and effective.
                        </p>
                        <p>
                            We recognize that data protection is an ongoing process, and we are
                            committed to continuously improving our practices to ensure that we meet
                            the highest standards of data protection and privacy. If you have any
                            questions or concerns about our privacy practices, please do not hesitate
                            to contact us.
                        </p>
                        <p>
                            Please read this Policy carefully. By using our services, accessing our
                            website, or providing us with your personal information, you acknowledge
                            that you have read, understood, and agree to the terms of this Policy.
                        </p>

                        <p>
                            THIS POLICY CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU, THE USER, AND US,
                            REGARDING YOUR USE OF OUR ONLINE PLATFORMS. BY CREATING AN ACCOUNT, USING
                            OUR SERVICES OR INTERACTING IN ANY WAY WITH OUR PLATFORMS, YOU CONSENT TO
                            OUR COLLECTION, USE, ACCESS, PROCESSING, STORAGE, DISCLOSURE, TRANSFER,
                            AND PROTECTION OF YOUR PERSONAL INFORMATION ACCORDING TO THE TERMS
                            OUTLINED IN THIS POLICY. THROUGHOUT THIS POLICY, THE TERMS "WEBSITE" OR
                            "PLATFORM" MAY BE USED TO REFER TO NUCHAIN INTERCHANGEABLY.
                        </p>
                        <p>
                            TO CONFIRM YOUR AGREEMENT WITH THIS POLICY, YOU MIGHT NEED TO SELECT THE
                            CHECKBOX NEXT TO "I AGREE WITH THE TERMS & CONDITIONS." IF YOU DO NOT
                            ACCEPT THIS POLICY, PLEASE CEASE USING OR ACCESSING THIS PLATFORM AS WELL
                            AS OUR SERVICES. YOU HAVE THE CHOICE TO COMPLY WITH OR OPT OUT OF GDPR
                            PRIVACY REGULATIONS. ANY DECISIONS TO GRANT OR WITHDRAW CONSENT MUST BE
                            COMMUNICATED TO US IN WRITING.
                        </p>
                        <ol>
                            <li><h2>Introduction</h2></li>
                            <ol type="a">
                                <li><h3>Overview of the policy and its purpose</h3></li>
                                <p>
                                    NuChain, a patented blockchain framework offering flexibility for
                                    diverse applications and boasting unparalleled security. It is a
                                    Layer-0 blockchain platform that would enable enterprises to reach,
                                    scale operations and optimize output.
                                </p>
                                <p>NuChain comprises of 4 products which includes the following,</p>
                                <ul>
                                    <li>
                                        <strong>NuCode </strong>is a product that offers a user-friendly
                                        coding language for custom blockchain solutions on NuChain.
                                    </li>
                                    <li>
                                        <strong>Validator </strong>is a key participant in a blockchain
                                        network, responsible for validating and approving new transactions
                                        in the blockchain. With our unique architecture and consensus
                                        mechanism, we also offer a user validator program to onboard users
                                        to run validator nodes and contribute to the network. Users are
                                        offered generous rewards in return.
                                    </li>
                                    <li>
                                        <strong>NuScan/NuChain Scan/NuChain Explorer </strong>is a
                                        comprehensive web3 explorer that is designed for the NuChain
                                        Blockchain. The users are provided with detailed information and
                                        insights into the blockchain's activities through the features like
                                        a dashboard, validator information, block and transaction tracking,
                                        relayer details, asset management, account information, network
                                        parameters, smart contract interactions, wallet integration and
                                        analytics.
                                    </li>
                                    <li>
                                        <strong>NuWallet (NuChain Wallet) </strong>emerges as a state of the
                                        art web 3 wallet, meticulously crafted users with an unparalleled
                                        blend of security, accessibility, and intuitive functionality for
                                        the seamless management of their digital assets and decentralized
                                        identities (DID).
                                    </li>
                                </ul>
                                <p>
                                    Firmly rooted in the ethos of decentralization and safeguarding
                                    privacy, NuChain wallet stands as a beacon in the realm of digital
                                    finance, poised to empower individuals in navigating the complexities
                                    of our swiftly evolving digital ecosystem.
                                </p>
                                <p>
                                    NuChain's products are tailored as per the requirements of
                                    Governments, Institutions, and Intergovernmental Organizations,
                                    enabling them to deploy public-facing solutions. NuChain will help
                                    organizations create and deploy their own L1 and L2 chain.
                                </p>
                                <p>
                                    Our services are organized into six verticals to cater specific needs
                                    and requirements which includes, E-Governance, Banking & Finance,
                                    Travel, Real World Asset (RWA), Tokenization, Gaming and
                                    Entertainments.
                                </p>
                                <p>
                                    We believe that privacy is a fundamental right, and we are committed
                                    to protecting the privacy and security of our users' personal
                                    information on this platform. As this blockchain platform continues to
                                    evolve, it is essential to have clear guidelines and policies in place
                                    to ensure that user data is collected, stored, and used responsibly.
                                </p>
                                <p>
                                    This Privacy Policy outlines our commitment to privacy, including the
                                    types of information we collect, how we use that information, and the
                                    steps we take to protect user privacy.
                                </p>
                                <p>
                                    BY USING OUR PLATFORM, YOU CONSENT TO THE PRACTICES OUTLINED IN THIS
                                    PRIVACY POLICY.
                                </p>
                                <li><h3>Scope of the Policy</h3></li>
                                <p>
                                    This privacy policy applies to all personal data processed by NuChain
                                    in connection with the Services and products, including personal data
                                    collected through our website, and other digital or offline means. It
                                    also applies to personal data collected from our clients, partners,
                                    vendors, and other third parties.
                                </p>
                                <li><h3>Definitions</h3></li>
                                <p>
                                    Throughout this privacy policy, certain terms may have specific
                                    meanings, as defined by the GDPR. These include:
                                </p>
                                <p>
                                    "Consent" of the data subject means any freely given, specific,
                                    informed, and unambiguous indication of the data subject's wishes by
                                    which he or she, by a statement or by a clear affirmative action,
                                    signifies agreement to the processing of personal data relating to him
                                    or her.
                                </p>
                                <p>
                                    "Controller" means the natural or legal person, public authority,
                                    agency, or other body which, alone or jointly with others, determines
                                    the purposes and means of the processing of personal data.
                                </p>
                                <p>
                                    “Data Protection” means any legal regulations concerning data
                                    protection, data security, or privacy. This includes, but is not
                                    limited to, GDPR and any laws governing personal data, sensitive
                                    personal data, or data collected through outbound phone calls, emails,
                                    faxes, and other forms of communication.
                                </p>
                                <p>
                                    it also covers any legislation related to the safeguarding, security,
                                    or confidentiality of data in communication contexts..
                                </p>
                                <p>
                                    "Data Subject" means the individual to whom the personal data relates.
                                </p>
                                <p>
                                    "Personal data" means any information relating to an identified or
                                    identifiable natural person (data subject); an identifiable natural
                                    person is one who can be identified, directly or indirectly, in
                                    particular by reference to an identifier such as a name, an
                                    identification number, location data, an online identifier, or to one
                                    or more factors specific to the physical, physiological, genetic,
                                    mental, economic, cultural or social identity of that natural person.
                                </p>
                                <p>
                                    "Personal Data Breach" means a breach of security leading to the
                                    accidental or unlawful destruction, loss, alteration, unauthorized
                                    disclosure of, or access to, personal data transmitted, stored or
                                    otherwise processed.
                                </p>
                                <p>
                                    "Processing" means any operation or set of operations performed on
                                    personal data, or on sets of personal data whether or not by automated
                                    means such as collection, recording, organization, structuring,
                                    storage, adaptation, or alteration, retrieval, consultation, use,
                                    disclosure by transmission, dissemination or otherwise making
                                    available, erasure, or destruction.
                                </p>
                                <p>
                                    "Processor" means a natural or legal person, public authority, agency
                                    or other body which processes personal data on behalf of the
                                    controller.
                                </p>
                                <p>
                                    "Sensitive Personal Data or Information"means & encompasses distinct
                                    categories of personal details about an individual, which may include:
                                    (i) any data provided to us in connection with the Service; (ii) data
                                    received for processing, whether stored, managed under a legal
                                    contract, or otherwise; (iii) biometric identifiers; (iv) financial
                                    details, including bank accounts, credit and debit card details, and
                                    other payment data; (v) medical history and records; (vi) passwords;
                                    (vii) information related to an individual's physical, physiological,
                                    and mental health; (viii) sexual orientation.
                                </p>
                                <p>
                                    "Special categories of personal data" means sensitive data, such as
                                    data revealing racial or ethnic origin, political opinions, religious
                                    or philosophical beliefs, trade union membership, genetic data,
                                    biometric data, data concerning health or data concerning a natural
                                    person's sex life or sexual orientation.
                                </p>
                                <p>
                                    "Supervisory authority" means an independent public authority
                                    responsible for monitoring the application of the GDPR, including
                                    investigating complaints and conducting audits and inspections.
                                </p>
                                <p>
                                    “Third Parties” refers to any entity other than Nuchain, the Data
                                    Subject (the individual whose data is processed), and the persons who,
                                    under the direct authority of Nuchain, are authorized to process the
                                    data.
                                </p>
                            </ol>
                            <li>
                                <h2>
                                    Eligibility Criteria for for Accessing Our Products and Services
                                </h2>
                            </li>
                            <p>
                                Our products and services are exclusively designed for users who are 18
                                years of age or older. We do not deliberately gather personal data from
                                individuals recognized as children. By utilizing our services and
                                products, you affirm that you are 18 years old or older. Use of our
                                services by anyone younger is considered unlicensed and a violation of
                                our terms of service.
                            </p>
                            <p>
                                In the event that personal information from minors is inadvertently
                                collected, we will take appropriate and legally permissible measures to
                                remove such information from our databases. Consequently, any accounts
                                linked to this information will be terminated and access to our products
                                and services will be withdrawn.
                            </p>
                            <p>
                                If you are a parent or guardian and discover that your child has
                                provided us with personal information without your consent, please
                                contact us immediately at <a href="">https://nuchain.co/contact-us</a>.
                                You can request to access, amend, or remove your child's information and
                                exercise any additional rights you might have, such as opting out of the
                                processing of this information.
                            </p>
                            <li><h2>Collection Of Personal Data</h2></li>
                            <ol type="a">
                                <li><h3>Definition of personal data</h3></li>
                                <p>
                                    Personal data refers to any information relating to an identified or
                                    identifiable natural person, directly or indirectly. This can include
                                    a person's name, identification number, location data, online
                                    identifier, physical, physiological, genetic, mental, economic,
                                    cultural or social identity, or any other factor that is specific to
                                    the individual.
                                </p>
                                <p>
                                    An identified natural person is someone who can be identified,
                                    directly or indirectly, by reference to an identifier such as a name,
                                    identification number, location data, online identifier, or other
                                    factors that are specific to that person. An identifiable natural
                                    person is someone who can be identified, directly or indirectly, in
                                    particular by reference to an identifier or to one or more factors
                                    specific to their physical, physiological, genetic, mental, economic,
                                    cultural or social identity.
                                </p>
                                <p>
                                    Personal data can include a wide range of information, such as an
                                    individual's name, address, email address, phone number, date of
                                    birth, social security number, financial information, health
                                    information, and biometric data. It can also include information about
                                    an individual's preferences, behavior, and interactions with others.
                                </p>
                                <p>
                                    Under the GDPR, personal data is protected as a fundamental right.
                                    Controllers and processors shall ensure that personal data is
                                    processed lawfully, fairly, and transparently, and that it is
                                    collected for specified, explicit, and legitimate purposes. They must
                                    also ensure that personal data is adequate, relevant, and limited to
                                    what is necessary for the purposes for which it is processed, and that
                                    it is accurate and kept up-to-date. Additionally, we shall ensure that
                                    personal data is kept secure and confidential, and that it is not
                                    transferred without adequate protection.
                                </p>
                                <li><h3>Types of personal data collected</h3></li>
                                <p>
                                    We collect and process various types of personal data about you when
                                    you use our Services. The types of personal data we may collect and
                                    the purposes for which we use them are described below.
                                </p>
                                <ul>
                                    <li>Information you provide to us directly:</li>
                                    <p>
                                        When you create a user account or register for our Services and
                                        products, you may provide us with personal data such as your name,
                                        email address, phone number, date of birth, and other contact or
                                        profile information. We may use this information to set up and
                                        manage your account, verify your identity, communicate with you
                                        about the Services, and personalize your experience.
                                    </p>
                                    <li>Information we collect automatically:</li>
                                    <p>
                                        We may also collect certain information automatically when you use
                                        our Services, such as your IP address, device type, browser type,
                                        operating system, and other technical information. We may use this
                                        information to improve the performance and functionality of our
                                        Services, analyze usage trends, and troubleshoot issues.
                                    </p>
                                    <li>Usage information:</li>
                                    <p>
                                        We may collect information about how you use our Services, such as
                                        the features you use, the pages you visit, the content you view, and
                                        the actions you take. We may use this information to personalize
                                        your experience, understand your preferences, and improve our
                                        Services.
                                    </p>
                                    <li>Location data:</li>
                                    <p>
                                        We may collect and process information about your location if you
                                        give us permission to do so. We may use this information to provide
                                        you with location-based services, such as personalized content and
                                        advertising.
                                    </p>
                                    <li>Financial Information</li>
                                    <p>
                                        We collect details such as your bank account numbers, credit or
                                        debit card details, transaction and trading history, UPI details,
                                        tax identification numbers, and Digital Assets information,
                                        including wallet addresses, to facilitate transactions on the Online
                                        Platforms. For Know Your Client (KYC) requirements information will
                                        be provided by you to us.
                                    </p>
                                    <li>Special categories of personal data:</li>
                                    <p>
                                        In some cases, we may collect special categories of personal data,
                                        such as health information or other sensitive data, if you provide
                                        it to us voluntarily or if it is necessary for us to provide certain
                                        Services to you. We will only process such data with your explicit
                                        consent or as otherwise permitted by applicable law.
                                    </p>
                                    <p>
                                        WE OFFER A SECURE ENVIRONMENT FOR CONDUCTING SENSITIVE DATA
                                        TRANSACTIONS AS THERE IS THE ADOPTION OF DECENTRALISED ARCHITECTURE
                                        AND STRONG SECURITY FEATURES.
                                    </p>
                                    <p>
                                        WE WILL ONLY USE YOUR PERSONAL DATA FOR THE PURPOSES FOR WHICH WE
                                        COLLECTED IT, UNLESS WE REASONABLY CONSIDER THAT WE NEED TO USE IT
                                        FOR ANOTHER REASON THAT IS COMPATIBLE WITH THE ORIGINAL PURPOSE. IF
                                        WE NEED TO USE YOUR PERSONAL DATA FOR AN UNRELATED PURPOSE, WE WILL
                                        NOTIFY YOU AND EXPLAIN THE LEGAL BASIS WHICH ALLOWS US TO DO SO.
                                    </p>
                                </ul>
                                <li><h3>Information collected automatically</h3></li>
                                <p>
                                    Information collected automatically refers to the data that is
                                    collected by the website or application without the user explicitly
                                    providing it. This data is collected through various means such as
                                    cookies, log files, web beacons, and other tracking technologies. This
                                    information is used to improve the user experience, provide relevant
                                    content, and analyze trends.
                                </p>
                                <p>
                                    Common examples of information collected automatically include the
                                    user's IP address, browser type and version, operating system, device
                                    type, screen resolution, language preference, and the website or
                                    application that referred the user to the website or application. This
                                    information is collected by the website or application and stored in
                                    log files.
                                </p>
                                <p>
                                    Log files contain information about the user's activity on the website
                                    or application, such as the pages viewed, the time spent on each page,
                                    the links clicked, and the date and time of each visit. This
                                    information is used to analyze trends, monitor the performance of the
                                    website or application, and improve the user experience.
                                </p>
                                <p>
                                    Cookies are small text files that are stored on the user's device when
                                    they visit a website or application. Cookies contain information about
                                    the user's activity on the website or application, such as their
                                    preferences and settings. This information is used to personalize the
                                    user experience, provide relevant content, and analyze trends.
                                </p>
                                <p>
                                    Web beacons are small images that are embedded in web pages and
                                    emails. They are used to track the user's activity on the website or
                                    application, such as the pages viewed and the links clicked. This
                                    information is used to analyze trends and improve the user experience.
                                </p>
                                <p>
                                    Overall, the information collected automatically is used to improve
                                    the user experience, provide relevant content, and analyze trends. It
                                    is important to note that this information is collected in a
                                    non-intrusive manner and is used solely for the purposes of improving
                                    the website or application. Additionally, we have a responsibility to
                                    ensure that this information is collected and used in compliance with
                                    applicable data protection laws, such as the GDPR.
                                </p>
                            </ol>
                            <li><h2>How Data is Collected</h2></li>
                            <ol type="a">
                                <li><h3>Direct collection</h3></li>
                                <p>
                                    We may collect personal data directly from our users when they sign up
                                    for our services or our products, fill out a contact form, or engage
                                    with us through our website, social media, or other channels. The
                                    personal data we collect may include name, email address, phone
                                    number, address, job title, company name, and other information
                                    relevant to the service we provide.
                                </p>
                                <li><h3>Indirect collection</h3></li>
                                <p>
                                    We may also collect personal data indirectly through our partners and
                                    service providers, such as when we use their services to process
                                    payments, analyze user behavior, or manage our marketing campaigns.
                                    The personal data we collect indirectly may include IP address,
                                    location data, browser type, device type, and other information
                                    relevant to the service we provide.
                                </p>
                                <li><h3>Collection from third parties</h3></li>
                                <p>
                                    We may collect personal data from publicly available sources, such as
                                    social media platforms, to enrich our marketing database or to verify
                                    the accuracy of the data we already have. The personal data we collect
                                    from third parties may include name, email address, phone number,
                                    address, job title, company name, and other information relevant to
                                    the service we provide.
                                </p>
                                <p>
                                    We may use plug-ins from social networks such as Twitter(X), LinkedIn
                                    etc. on our websites, when you activate them by clicking on them, the
                                    operators of the respective social networks may record that you are on
                                    our website and may use this information. This processing of your
                                    personal data lays in the responsibility of these individual social
                                    media platforms and occurs according to their privacy policies. Please
                                    check with these individual social media platforms regarding their
                                    privacy policies. We are not responsible for data collected by these
                                    individual social media platforms. We only use these platforms to
                                    inform the updates.
                                </p>
                                <p>
                                    WE ONLY COLLECT DATA THAT IS NECESSARY FOR THE PURPOSES FOR WHICH IT
                                    IS PROCESSED, AND WE TAKE APPROPRIATE MEASURES TO ENSURE THAT THE DATA
                                    WE COLLECT IS ACCURATE, UP-TO-DATE, AND RELEVANT TO THE SERVICE WE
                                    PROVIDE. WE ALSO ENSURE THAT ALL DATA IS COLLECTED AND PROCESSED IN
                                    COMPLIANCE WITH APPLICABLE DATA PROTECTION LAWS.
                                </p>
                            </ol>
                            <li><h2>Legal Basis for Processing Data</h2></li>
                            <ol type="a">
                                <li><h3>Consent</h3></li>
                                <p>
                                    When individuals give their consent to the processing of their
                                    personal data, it means they have given their explicit and informed
                                    agreement to the use of their data for specific purposes. For
                                    instance, if NuChain intends to collect personal data for marketing
                                    purposes, it will obtain explicit consent from individuals before
                                    using their data for this purpose. Individuals have the right to
                                    withdraw their consent at any time.
                                </p>
                                <li><h3>Contractual necessity</h3></li>
                                <p>
                                    NuChain may need to process personal data that is necessary for the
                                    performance of a contract with an individual, or to take steps at the
                                    request of the individual prior to entering into a contract. For
                                    instance, if NuChain provides an online service, it may need to
                                    process personal data to ensure the service is provided to the
                                    individual. This legal basis applies only to the extent necessary to
                                    perform the contract or take steps at the request of the individual.
                                </p>
                                <li><h3>Legal obligation</h3></li>
                                <p>
                                    NuChain may need to process personal data to comply with a legal
                                    obligation to which it is subject. For instance, if NuChain is
                                    required by law to retain certain personal data for a specified
                                    period, it will process that data for that purpose. This legal basis
                                    applies only to the extent necessary to comply with the legal
                                    obligation.
                                </p>
                                <li><h3>Vital interests</h3></li>
                                <p>
                                    NuChain may process personal data when it is necessary to protect the
                                    vital interests of the data subject or another person. For instance,
                                    if there is a medical emergency involving an individual, NuChain may
                                    need to process personal data to provide medical assistance. This
                                    legal basis applies only to the extent necessary to protect the vital
                                    interests of the data subject or another person.
                                </p>
                                <li><h3>Legitimate interests</h3></li>
                                <p>
                                    NuChain may process personal data when it is necessary for the
                                    legitimate interests of NuChain or a third party, except where such
                                    interests are overridden by the interests, rights, or freedoms of the
                                    data subject. For instance, if NuChain wants to process personal data
                                    for the purposes of fraud prevention, it will need to demonstrate that
                                    its legitimate interest in fraud prevention outweighs the privacy
                                    rights of the data subjects. This legal basis applies only to the
                                    extent necessary to achieve the legitimate interest and requires a
                                    balancing of interests test.
                                </p>
                                <p>
                                    IT IS IMPORTANT TO NOTE THAT NUCHAIN WILL ONLY PROCESS PERSONAL DATA
                                    FOR THE PURPOSES FOR WHICH IT WAS COLLECTED, AND WILL NOT USE THE DATA
                                    FOR ANY OTHER PURPOSE WITHOUT OBTAINING ADDITIONAL CONSENT FROM THE
                                    DATA SUBJECT OR UNLESS PERMITTED BY LAW. ADDITIONALLY, IF NUCHAIN
                                    INTENDS TO PROCESS PERSONAL DATA FOR A NEW PURPOSE THAT IS NOT
                                    COMPATIBLE WITH THE ORIGINAL PURPOSE, IT WILL PROVIDE DATA SUBJECTS
                                    WITH INFORMATION ABOUT THE NEW PURPOSE AND OBTAIN THEIR CONSENT IF
                                    NECESSARY.
                                </p>
                            </ol>
                            <li><h2>Use of Data</h2></li>
                            <ol type="a">
                                <li><h3>Purpose of Data Processing</h3></li>
                                <ul>
                                    <li>
                                        To provide our services and products: We process personal data in
                                        order to provide our products and services to our users. This
                                        includes processing personal data to create user accounts, enabling
                                        users to create and share content, providing customer support, and
                                        improving our products and services.
                                    </li>
                                    <li>
                                        To communicate with users: We may process personal data to
                                        communicate with our users, including responding to their requests,
                                        sending newsletters or promotional messages, and providing updates
                                        about our products and services.
                                    </li>
                                    <li>
                                        To personalize user experience: We may process personal data to
                                        tailor our products and services to individual users' interests,
                                        preferences, and usage patterns. This includes analyzing data about
                                        user behavior, preferences, and interactions with our products and
                                        services.
                                    </li>
                                    <li>
                                        To comply with legal obligations: We may process personal data to
                                        comply with legal obligations, such as responding to legal requests
                                        or orders, preventing fraud or other illegal activities, and
                                        enforcing our terms of service.
                                    </li>
                                    <li>
                                        To improve our products and services: We may process personal data
                                        for research and development purposes, including testing new
                                        features and functionality, analyzing usage patterns, and improving
                                        the quality and performance of our products and services.
                                    </li>
                                </ul>
                                <li><h3>Sharing of Data and International Data Transfers</h3></li>
                                <p>
                                    We may share personal data with third-party service providers, such as
                                    payment processors or hosting providers, in order to provide our
                                    products and services. We may also share personal data with business
                                    partners or other third parties for marketing or advertising purposes,
                                    subject to the user's consent where required by law.
                                </p>
                                <p>
                                    We may transfer personal data to international countries outside the
                                    jurisdiction where we are based, including to countries that do not
                                    provide an adequate level of data protection. In such cases, we will
                                    ensure that appropriate safeguards are in place to protect personal
                                    data, such as using standard contractual clauses or relying on other
                                    legal mechanisms for data transfers.
                                </p>
                                <p>
                                    Users have the right to object to the processing of their personal
                                    data for direct marketing purposes, and can exercise their other data
                                    subject rights as provided for under GDPR. If you have any questions
                                    or concerns about our use of personal data, please contact us at the
                                    contact information provided.
                                </p>
                            </ol>
                            <li><h2>Consent</h2></li>
                            <ol type="a">
                                <li><h3>Requirements</h3></li>
                                <p>
                                    Consent is one of the legal basis for processing personal data under
                                    this Policy. In order for consent to be valid, it must meet the
                                    following requirements:
                                </p>
                                <ul>
                                    <li>
                                        Freely given: Consent must be given voluntarily and without
                                        coercion. Users must not be required to give consent in order to
                                        access a service, and consent must not be a condition of a contract.
                                    </li>
                                    <li>
                                        Specific: Consent must be specific to the purpose for which it is
                                        given. Users must be informed of the exact purpose for which their
                                        data will be processed, and must explicitly agree to that purpose.
                                    </li>
                                    <li>
                                        Informed: Consent must be informed, meaning users must be provided
                                        with clear and understandable information about the processing of
                                        their personal data, including the identity of the data controller,
                                        the purposes of processing, and any third-party recipients.
                                    </li>
                                    <li>
                                        Unambiguous: Consent must be unambiguous, meaning it must be given
                                        through a clear affirmative action. Pre-ticked boxes or inactivity
                                        do not constitute valid consent.
                                    </li>
                                    <li>
                                        Revocable: Users must have the right to withdraw their consent at
                                        any time, and withdrawing consent must be as easy as giving it.
                                    </li>
                                </ul>
                                <li><h3>How consent will be obtained</h3></li>
                                <p>
                                    We will obtain consent from users in a clear and transparent manner,
                                    and will ensure that the consent meets the above requirements.
                                </p>
                                <p>
                                    We will provide users with an easy and accessible way to withdraw
                                    their consent, and will honor all requests to withdraw consent in a
                                    timely manner.
                                </p>
                                <p>
                                    Upon using our services you provide us the consent that you comply
                                    with the practices outlined in this policy.
                                </p>
                                <p>
                                    If you have any questions or concerns about our use of consent or wish
                                    to withdraw your consent, please contact us at the contact information
                                    provided .
                                </p>
                            </ol>
                            <li><h2>Rights of Data Subjects</h2></li>
                            <ol type="a">
                                <li><h3>Right to Access</h3></li>
                                <p>
                                    This right allows you to obtain confirmation as to whether or not
                                    personal data concerning you is being processed by us, and to access a
                                    copy of the data. You can request information on the categories of
                                    data being processed, the purposes of the processing, any recipients
                                    of the data, and the retention periods. You may also request a copy of
                                    the data being processed, which should be provided in a commonly used
                                    electronic format.
                                </p>
                                <li><h3>Right to Rectification</h3></li>
                                <p>
                                    If you believe that any of your personal data processed by us is
                                    inaccurate or incomplete, you have the right to request that we
                                    rectify it. We will take reasonable steps to ensure that any
                                    inaccurate data is corrected and that any incomplete data is
                                    completed, taking into account the purposes for which it was
                                    processed.
                                </p>
                                <li><h3>Right to Erasure</h3></li>
                                <p>
                                    Also known as the "right to be forgotten," this right allows you to
                                    request the deletion of your personal data in certain circumstances.
                                    This includes situations where the data is no longer necessary for the
                                    purposes for which it was collected, when you withdraw your consent
                                    for processing, or when you object to the processing of the data.
                                    However, there may be legal or other reasons why we need to retain
                                    your data, and we will inform you if this is the case.
                                </p>
                                <li><h3>Right to Restrict Processing</h3></li>
                                <p>
                                    In some cases, you may wish to limit the processing of your personal
                                    data. This right allows you to request that we restrict the processing
                                    of your data in certain circumstances, such as when you contest the
                                    accuracy of the data or when you object to the processing of the data.
                                    We will continue to store your data, but we will not process it
                                    further without your consent, unless it is necessary for legal reasons
                                    or to protect the rights of another person.
                                </p>
                                <li><h3>Right to Data Portability</h3></li>
                                <p>
                                    You have the right to obtain a copy of your personal data in a
                                    structured, commonly used, and machine-readable format, and to
                                    transfer that data to another data controller. This right only applies
                                    to data that you have provided to us, and only when the processing is
                                    based on your consent or on a contract.
                                </p>
                                <li><h3>Right to Object</h3></li>
                                <p>
                                    You have the right to object to the processing of your personal data
                                    in certain circumstances, such as when we are using the data for
                                    direct marketing purposes or when we are using it for research or
                                    statistical purposes. We will cease processing your data unless we can
                                    demonstrate compelling legitimate grounds for the processing, which
                                    override your interests, rights, and freedoms, or if the processing is
                                    necessary for the establishment, exercise, or defense of legal claims.
                                </p>
                                <li><h3>Right to Withdraw Consent</h3></li>
                                <p>
                                    If we are processing your personal data on the basis of your consent,
                                    you have the right to withdraw that consent at any time. This means
                                    that we will no longer process your data for the purpose for which you
                                    originally gave your consent, unless we have another legal basis for
                                    doing so. Withdrawing your consent will not affect the lawfulness of
                                    any processing that we have carried out prior to the withdrawal of
                                    consent.
                                </p>
                                <p>
                                    To exercise any of these rights, please contact us using the details
                                    provided in the "Contact Information" section below. We may need to
                                    verify your identity before we can respond to your request. We will
                                    respond to your request as soon as possible and in any event within
                                    one month of receipt of your request. However, if your request is
                                    complex or if we receive a number of requests from you, we may need to
                                    extend this period by a further two months. If we are unable to
                                    fulfill your request, we will explain why.
                                </p>
                            </ol>
                            <li><h2>Security Measures</h2></li>
                            <p>
                                NuChain prioritizes the safety and integrity of user data and
                                applications. Our robust, multi-layered security infrastructure is
                                designed to mitigate potential vulnerabilities.
                            </p>
                            <p>
                                NuChain embraces the immutability of blockchain, providing an
                                unalterable record of transactions. This feature enhances transparency,
                                deters fraud, and streamlines the auditing process.
                            </p>
                            <p>
                                NuChain is a reliable platform when it comes to managing private data,
                                including financial records, personal information, and intellectual
                                property. We have put in place strong security measures to protect
                                private data from hackers and unauthorized access.
                            </p>
                            <p>
                                NuChain offers a secure environment for conducting sensitive data
                                transactions by reason of decentralized architecture and strong security
                                features. The platform guards against unauthorized disclosure and
                                manipulation, users can use it with confidence to store, transmit, and
                                access sensitive data.
                            </p>
                            <ol type="a">
                                <li><h3>Technical Measures</h3></li>
                                <ul>
                                    <li>
                                        We use advanced cryptographic algorithms and consensus mechanisms to
                                        maintain integrity and confidentiality of transactions.
                                    </li>
                                    <li>
                                        We prevent unauthorized access and manipulation of sensitive data by
                                        encrypting transaction data and storing it in immutable blocks.
                                    </li>
                                    <li>
                                        We employ a Byzantine Fault Tolerant Proof-of-Reputation
                                        (PoR/BFTPoR) consensus mechanism maintaining decentralization and
                                        prioritizing security.
                                    </li>
                                    <li>
                                        We test for vulnerabilities such as injection attacks, cross site
                                        scripting (XSS), and data breaches to ensure robust security
                                        measures.
                                    </li>
                                    <li>
                                        We regularly conduct independent security audits by industry-leading
                                        firms to maintain the highest level of platform security.
                                    </li>
                                    <li>
                                        We use advanced technical authentication, employ encryption
                                        protocols and decentralized identity mechanisms for our wallets to
                                        safeguard user privacy and protect sensitive information from
                                        unauthorized access.
                                    </li>
                                    <li>
                                        We integrate privacy preserving functionalities like zero- knowledge
                                        proofs to cater to applications requiring enhanced data
                                        confidentiality.
                                    </li>
                                    <li>
                                        We implement HTTPS (Hypertext Transfer Protocol Secure) to ensure
                                        secure data transmission between the server and the user's browser,
                                        this encryption protocol enhances data integrity and
                                        confidentiality.
                                    </li>
                                    <li>
                                        We maintain up-to-date software and hardware to ensure the security
                                        of our systems.
                                    </li>
                                </ul>
                                <li><h3>Organizational Measures</h3></li>
                                <ul>
                                    <li>
                                        We limit access to personal data to only those employees,
                                        contractors, and third parties who require it for legitimate
                                        business purposes.
                                    </li>
                                    <li>
                                        We provide regular training to our employees on data protection and
                                        privacy practices.
                                    </li>
                                    <li>
                                        We implement access controls and password policies to ensure that
                                        personal data is only accessible to authorized personnel.
                                    </li>
                                    <li>
                                        We have established incident response procedures in case of a data
                                        breach, and will notify affected individuals and authorities as
                                        required by law.
                                    </li>
                                </ul>
                                <li><h3>Third Party Security Obligations</h3></li>
                                <ul>
                                    <li>
                                        We require all third-party service providers who process personal
                                        data on our behalf to meet the same security standards as we do.
                                        This includes requiring them to implement appropriate technical and
                                        organizational measures to protect personal data.
                                    </li>
                                    <li>
                                        We require third-party service providers to provide us with
                                        assurance that they comply with data protection laws and will handle
                                        personal data in a manner consistent with our instructions.
                                    </li>
                                </ul>
                                <li><h3>Incident Response Procedures</h3></li>
                                <p>
                                    The purpose of incident response procedures is to minimize the impact
                                    of an incident on us, our customers, and other stakeholders. We have
                                    the below mentioned incident response procedures in place that address
                                    key components:
                                </p>
                                <ul>
                                    <li>
                                        Detection and Response: The first step in incident response is
                                        detecting that a security breach has occurred. We have monitoring
                                        systems in place to detect incidents and respond quickly to contain
                                        them. This may involve shutting down affected systems or isolating
                                        compromised devices to prevent further damage.
                                    </li>
                                    <li>
                                        Investigation: Once an incident has been detected, we shall conduct
                                        a thorough investigation to determine the cause of the incident and
                                        the extent of the damage. This may involve reviewing logs, analyzing
                                        system activity, and interviewing personnel who were involved.
                                    </li>
                                    <li>
                                        Notification: If a personal data breach has occurred, we shall
                                        notify the relevant supervisory authority and affected data subjects
                                        within 72 hours of becoming aware of the breach. Notification shall
                                        include a description of the nature of the breach, the types of
                                        personal data affected, and the steps being taken to mitigate the
                                        impact of the breach.
                                    </li>
                                    <li>
                                        Mitigation: We shall take steps to mitigate the impact of the
                                        incident, such as restoring data from backups, patching
                                        vulnerabilities, or implementing additional security controls to
                                        prevent similar incidents from occurring in the future.
                                    </li>
                                    <li>
                                        Follow-up: After an incident has been resolved, we shall conduct a
                                        post-incident review to identify lessons learned and update incident
                                        response procedures as needed.
                                    </li>
                                </ul>
                                <p>
                                    THE PLATFORM'S ROUST ARCHITECTURE, COUPLED WITH ITS INNOVATIVE
                                    CONSENSUS MECHANISM AND ADVANCED SECURITY PROTOCOLS ENSURES DATA
                                    INTEGRITY, IMMUTABILITY, AND RESISTANCE TO SECURITY BREACHES.
                                </p>
                                <p>
                                    WHILE WE TAKE REASONABLE STEPS TO PROTECT PERSONAL DATA, NO SECURITY
                                    MEASURE IS PERFECT AND WE CANNOT GUARANTEE THE ABSOLUTE SECURITY OF
                                    PERSONAL DATA. IN THE EVENT OF A SECURITY BREACH, WE WILL TAKE ALL
                                    NECESSARY STEPS TO MITIGATE THE IMPACT OF THE BREACH AND NOTIFY
                                    AFFECTED INDIVIDUALS AND AUTHORITIES AS REQUIRED BY LAW.
                                </p>
                            </ol>
                            <li><h3>Data Retention</h3></li>
                            <p>
                                We retain personal data only for as long as necessary to fulfill the
                                purposes for which it was collected, unless a longer retention period is
                                required by law or our legitimate interests. The retention periods for
                                different types of personal data depend on various factors, including
                                the nature of the data and the purposes for which it was collected.
                            </p>
                            <ol type="a">
                                <li><h3>Retention Periods</h3></li>
                                <p>We retain personal data for the following periods:</p>
                                <ul>
                                    <li>
                                        Personal data collected for contractual purposes will be retained
                                        for the duration of the contract and for a period thereafter as
                                        required by applicable law.
                                    </li>
                                    <li>
                                        Personal data collected for marketing purposes will be retained
                                        until you withdraw your consent or until you exercise your right to
                                        object.
                                    </li>
                                    <li>
                                        Personal data collected for compliance with legal obligations will
                                        be retained for the period required by the applicable law.
                                    </li>
                                </ul>
                                <li><h3>Criteria for Determining Retention Periods</h3></li>
                                <p>
                                    We determine the appropriate retention period for personal data based
                                    on the following criteria:
                                </p>
                                <ul>
                                    <li>The purpose for which the personal data was collected.</li>
                                    <li>The nature of personal data.</li>
                                    <li>
                                        The legal or regulatory obligations that require us to retain the
                                        personal data.
                                    </li>
                                    <li>Our legitimate interests in retaining the personal data.</li>
                                </ul>
                                <li><h3>Exceptions to Retention Periods</h3></li>
                                <p>
                                    In some circumstances, we may retain personal data for a longer period
                                    than stated above if necessary to comply with our legal obligations or
                                    to protect our legitimate interests. For example, we may retain
                                    personal data for longer periods to resolve disputes, prevent fraud,
                                    or enforce our agreements.
                                </p>
                                <p>
                                    If we no longer need personal data for any purpose and are not
                                    required by law to retain it, we will securely delete or destroy it in
                                    accordance with our data retention and disposal policies.
                                </p>
                            </ol>
                            <li><h2>Special Provisions</h2></li>
                            <ol type="a">
                                <li><h3>Validator</h3></li>
                                <p>
                                    The primary objective of the Blockchain Validator platform is to offer
                                    a secure, efficient, user-friendly environment for the individuals and
                                    companies to participate in blockchain validation, purchase validator
                                    plans, and manage their staking, reward and referral. Blockchain
                                    Validator streamlines the process of becoming a validator in
                                    blockchain networks offering comprehensive features for efficient
                                    validation and performance tracking.
                                </p>
                                <ul>
                                    <li>Information you provide to us:</li>
                                    <ol type="I">
                                        <li>
                                            For the SignUp process email and password will be provided by you.
                                        </li>
                                        <li>
                                            Based on the type of the user (Individual/Company), users should
                                            fill out a form with relevant details.
                                        </li>
                                        <li>
                                            For purchasing the validator plans payment details will be
                                            provided by the users.
                                        </li>
                                        <li>
                                            While connecting the NuChain Wallet to the Validator tool you will
                                            be providing the wallet address.
                                        </li>
                                        <li>
                                            You will also provide your name, city, occupation details, phone
                                            number, KYC/KYB documents to be uploaded by you as per the
                                            selection of Individual/Company such as Aadhar Pdf, PAN Card.jpg
                                            in KYC form and Certificate of Incorporation and Tax certicate in
                                            KYB form.
                                        </li>
                                    </ol>
                                    <li>Use of the data:</li>
                                    <p>
                                        The data provided by you will be used for the purpose of
                                        participation in blockchain validation, purchasing validator plans,
                                        and for managing their staking, reward and referral.
                                    </p>
                                    <li>Technical Specifications and Third-Party Integrations:</li>
                                    <p>
                                        Django is utilized as a backend technology in handling diverse tasks
                                        associated with data processing and server-side functionalities. The
                                        third-party integrations such as Hypersign API (Used for KYC),
                                        Transak API (used for handling payments), Zendesk (used for live
                                        chat functionality), Infura (used for smart contract), and Signzy
                                        (used for E-sign) are used by us.
                                    </p>
                                    <li>Security measures:</li>
                                    <p>
                                        We regularly perform penetration testing to identify and address
                                        potential security libel. This involves simulating
                                        real-world-cyber-attacks to assess the system's resilience and
                                        discover any weakness in the security architecture; we conduct
                                        through code reviews to identify and rectify security-related issues
                                        within the source code. This includes looking over the codebase for
                                        vulnerabilities such as injection attacks, data breaches, and other
                                        security threats. We specifically focus on testing the robustness of
                                        the authentication mechanisms and ensure that user accounts are
                                        secure, and sensitive data is protected through proper encryption
                                        and access controls. And we also verify the effectiveness of the
                                        secure connection (HTTPS) by evaluating the encryption protocols in
                                        place and ensuring the confidentiality and integrity of data during
                                        transmission.
                                    </p>
                                </ul>
                                <li><h3>NuChain Wallet/ NuWallet</h3></li>
                                <p>
                                    NuWallet is a cutting-edge Web3 Wallet, expertly designed to offer
                                    users an exceptional combination of security, accessibility, and
                                    intuitive functionality for managing their digital assets and
                                    decentralized identities (DID) effortlessly. Rooted in the principles
                                    of decentralization and privacy protection, NuWallet is a leading
                                    force in digital finance, empowering individuals to navigate the
                                    complexities of the rapidly evolving digital ecosystem.
                                </p>
                                <p>
                                    NuWallet provides users with comprehensive functionalities like
                                    sending, receiving and swapping assets. It supports multiple
                                    blockchains, tokens, and accounts. It includes an address book,
                                    maintains transaction history, and for seamless integration with the
                                    decentralized applications it has the feature of DApps browser.
                                </p>
                                <ul>
                                    <li>Information you provide to us:</li>
                                    <p>
                                        Users can create a new wallet or import existing wallet in the
                                        application (the “App”), and they can send and receive tokens. You
                                        can swap tokens directly from their wallet using the swap feature,
                                        this allows the users to exchange one type of token for another
                                        without leaving the wallet interface. Users can create their own
                                        Decentralized Identifier (DID), which serves as a sovereign identity
                                        within the blockchain ecosystem.
                                    </p>
                                    <p>
                                        While creating the Decentralized Identity (DID) creation you will
                                        provide the information such as email address, country name,
                                        selected documents, and the chosen username.
                                    </p>
                                    <li>
                                        <p>
                                            <u>Creation of new Wallet:</u> When you create a wallet you will
                                            be setting up a new wallet name and creating and confirming a
                                            secret PIN. The secret PIN that you have created will be saved in
                                            your mobile's cache. The secret PIN is a numeric code chosen by
                                            you to secure your wallet. It adds an extra layer of security for
                                            accessing your wallet's fund and authorizing transactions. Users
                                            are advised to select a strong and memorable PIN and keep it
                                            confidential to prevent unauthorized access.
                                        </p>
                                        <p>
                                            When creating a new wallet there is a generation of Seed Phrase. A
                                            “Seed Phrase” refers to a list of randomly generated words used
                                            for wallet recovery. It is unique to each wallet and provides a
                                            secure method for restoring wallet access. Users should keep the
                                            seed phrase secure and never share it to anyone.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <u>Import of existing Wallet:</u> Upon importing an existing
                                            wallet you will enter the seed phrase for the wallet being
                                            imported and set a new security PIN for your existing wallet and
                                            confirm the new PIN.
                                        </p>
                                        <p>
                                            When you create a wallet you will be assigned with a private key.
                                            You acknowledge and agree that you shall be solely responsible for
                                            maintaining the confidentiality and security of your private key.
                                            We shall have no liability whatsoever in connection with
                                            activities that occur on your wallet with or without your
                                            authorization.
                                        </p>
                                        <p>
                                            When you create a wallet you are strongly advised to take
                                            precautions in order to avoid loss of access to and/or control
                                            over your wallet.
                                        </p>
                                        <p>
                                            By navigating to the “Security” feature you can fortify your
                                            wallet with a range of security measures. You have the power to
                                            enable or disable biometric login, reset your PIN, review your
                                            recovery phrase, and access your private key securely.
                                        </p>
                                        <p>
                                            You will also provide the transaction details such as the type of
                                            transaction, status, amount, and date/time.
                                        </p>
                                    </li>
                                    <li>Use of the data:</li>
                                    <p>
                                        The information that you provide to us enables you to use the
                                        comprehensive set of features and functionalities tailored
                                        specifically for mobile users. From seamless token transactions to
                                        intuitive portfolio management tools, our mobile app is designed to
                                        accommodate the diverse needs of cryptocurrency enthusiasts,
                                        investors, and developers on the go. By continually innovating and
                                        enhancing our mobile feature set, we strive to provide an
                                        unparalleled mobile experience that meets the evolving demands of
                                        our user community.
                                    </p>
                                    <li>Technical Specifications and Third-Party Integrations:</li>
                                    <p>
                                        The technical specifications such as JSON Web Tokens (JWT) are used
                                        for authentication and for encryption, Advanced Encryption Standards
                                        (AES) is employed. We integrate with third-party DApps to enable
                                        seamless redirection from the browser to the respective mobile app
                                        or webpage.
                                    </p>
                                    <li>Security measures:</li>
                                    <p>
                                        Privacy and data security are fundamental principles embedded in
                                        NuWallet's mobile app. We employ robust encryption protocols to
                                        safeguard user privacy and protect sensitive information from
                                        unauthorized access.
                                    </p>
                                    <p>
                                        NuWallet will undergo rigorous testing to ensure reliability,
                                        security, and performance. The testing process will include:
                                    </p>
                                    <ol type="I">
                                        <li>
                                            <p>
                                                <strong>Unit Testing: </strong>Testing individual components and
                                                functions to verify correctness and functionality.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Integration Testing:</strong>Testing the interaction
                                                between different modules and components to ensure seamless
                                                integration.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>End to end Testing:</strong>Testing the entire
                                                application flows from the user interaction to backend
                                                processing to validate overall functionality.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Security Testing:</strong>Testing for vulnerabilities
                                                such as injection attacks, cross-site scripting (XSS), and data
                                                breaches to ensure robust security measures.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Performance Testing:</strong>Testing for response times,
                                                scalability, and resource utilization to ensure optimal
                                                performance under various conditions.
                                            </p>
                                        </li>
                                    </ol>
                                    <p>
                                        With an unwavering commitment to user sovereignty and data
                                        integrity, NuWallet endeavors to furnish users with the tools and
                                        capabilities essential for assuming command over their financial
                                        portfolios and personal identities amidst the transformative
                                        landscape of blockchain technology and decentralized applications
                                        (DApps).
                                    </p>
                                </ul>
                            </ol>
                            <li><h2>Changes to the Policy</h2></li>
                            <ol type="a">
                                <li><h3>Notification of Changes</h3></li>
                                <p>
                                    We reserve the right to modify this privacy policy at any time. If we
                                    make any material changes to the policy, we will provide notice to
                                    data subjects through our website, email, or other appropriate means.
                                    We will also indicate the date of the most recent update to the
                                    policy.
                                </p>
                                <li><h3>Date of Last Update</h3></li>
                                <p>
                                    The privacy policy was last updated on [______]. We encourage data
                                    subjects to review this policy periodically to ensure that they are
                                    aware of any changes that may affect their rights or obligations.
                                </p>
                                <p>
                                    If you have any questions or concerns about our privacy policy, please
                                    contact us using the information provided in Section 13.
                                </p>
                            </ol>

                            <li><h2>Contact Information</h2></li>
                            <p>
                                To protect the security and privacy of your personal data, we will ask
                                you to provide proof of your identity before we can process your
                                request. We will respond to your request within fifteen days of
                                verifying your identity. Please contact us if you have any questions at
                                <a href="">https://nuchain.co/contact-us </a>
                            </p>
                            <li><h2>Complaints</h2></li>
                            <ol type="a">
                                <li><h3>Complaints Procedure</h3></li>
                                <ul>
                                    <li>
                                        We take all complaints about our handling of personal data seriously
                                        and are committed to addressing them promptly and effectively.
                                    </li>
                                    <li>
                                        Data subjects can submit a complaint about our data handling
                                        practices by contacting us using the information provided in the
                                        Contact Information section of this Policy.
                                    </li>
                                    <li>
                                        We will acknowledge receipt of the complaint and provide the data
                                        subject with a reference number for tracking purposes.
                                    </li>
                                </ul>
                                <li><h3>Investigation and Resolution</h3></li>
                                <ul>
                                    <li>
                                        We will investigate the complaint promptly and thoroughly, taking
                                        into account all relevant circumstances and any applicable legal
                                        requirements.
                                    </li>
                                    <li>
                                        We may request additional information or documentation from the data
                                        subject or any third parties involved in order to assist with the
                                        investigation.
                                    </li>
                                    <li>
                                        We will notify the data subject of the outcome of the investigation
                                        and any remedial action taken as a result of the complaint.
                                    </li>
                                </ul>
                                <li><h3>Timeframes</h3></li>
                                <ul>
                                    <li>
                                        We aim to respond to all complaints within 7 days of receipt.
                                        However, in some cases, we may require additional time to
                                        investigate the complaint fully. In such cases, we will notify the
                                        data subject of the reason for the delay and provide an estimated
                                        timeframe for resolution.
                                    </li>
                                </ul>
                                <li><h3>No Retaliation</h3></li>
                                <ul>
                                    <li>
                                        We will not retaliate against any data subject for submitting a
                                        complaint about our data handling practices.
                                    </li>
                                    <li>
                                        We will take appropriate measures to prevent any retaliation by
                                        employees, service providers or contractors against data subjects
                                        who submit a complaint.
                                    </li>
                                </ul>
                            </ol>
                            <li><h2>Grievance</h2></li>
                            <p>If you have any questions, feedback, or concerns about how we handle the collection, processing, use, disclosure, or security of your information, or any other aspects of this Policy, or if you need to exercise any of your data protection rights, please submit your inquiries in writing to our contact page at <a href=""> https://nuchain.co/contact-us </a>. We are committed to addressing all your concerns and ensuring clarity about our data practices.</p>
                        </ol>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default PrivacyPolicy
